import React from 'react';
import useForm from 'react-hook-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import {indigo} from '@material-ui/core/colors';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import {
    setNotifyInfo,
    setNotifySuccess,
    setNotifyError
} from '../core/notifications';
import {useHistory, useLocation} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    fabProgress: {
        color: indigo[600],
        position: 'absolute',
        top: 1,
        left: 1,
        zIndex: 1
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const LoginForm = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const {message: notifyLoading, ...loadingOptions} = setNotifyInfo({
        message: 'Init authorization'
    });
    const {message: notifySuccess, ...successOptions} = setNotifySuccess({
        message: 'Authorization success'
    });
    const {message: notifyError, ...errorOptions} = setNotifyError({
        message: 'Authorization failed'
    });

    const [{data, loading, error, response}, signIn] = useAxios(
        {
            url: 'auth',
            method: 'POST'
        },
        {manual: true}
    );

    const {register, handleSubmit, errors} = useForm();
    const onSubmit = (data: any) => {
        signIn({data});
    };

    React.useEffect(() => {
        if (loading) enqueueSnackbar(notifyLoading, loadingOptions);
    }, [loading]);

    React.useEffect(() => {
        if (error) {
            const {data}: any = error;
            if (data && typeof data === 'string') {
                enqueueSnackbar(data, errorOptions);
            } else {
                enqueueSnackbar(notifyError, errorOptions);
            }
        }
    }, [error]);

    React.useEffect(() => {
        if (data) localStorage.setItem('token', data);
    }, [data]);

    React.useEffect(() => {
        if (response) {
            if (response.status === 200 && response.statusText === 'OK') {
                enqueueSnackbar(notifySuccess, successOptions);
                history.push('/admin');
            }
        }
    }, [response]);

    React.useEffect(() => {
        const {pathname} = location;
        const token = localStorage.getItem('token');
        if (token && pathname === '/login') {
            history.push('/');
        }
    }, [location, history]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <div className={classes.wrapper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    {loading && (
                        <CircularProgress size={54} className={classes.fabProgress}/>
                    )}
                </div>
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
            </div>
            <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <TextField
                    id="login"
                    label="Login"
                    name="login"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputRef={register({
                        required: true
                    })}
                    error={errors.hasOwnProperty('login')}
                />
                <TextField
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    margin="dense"
                    variant="outlined"
                    inputRef={register({
                        required: true
                    })}
                    error={errors.hasOwnProperty('password')}
                    fullWidth
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign In
                </Button>
            </form>
        </Container>
    );
};

export default LoginForm;
