import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from './routes';
import {PrivateRoute} from './utils/PrivateRoute';
import ReactGA from 'react-ga';
import {hotjar} from 'react-hotjar';
import {config} from './config/drawer';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  const trakingId = config.trakingId;
  ReactGA.initialize(trakingId);

  const hjid: any = config.hjid;
  const hjsv: any = config.hjsv;

  hotjar.initialize(hjid, hjsv);
}

const App: React.FC = () => {
  useEffect(() => {
    if (isBrowser) {
      const pathname = window.location.pathname + (window.location.search ? window.location.search : '');
      ReactGA.pageview(pathname);
    }
  }, []);

  return (
      <Router>
        <Switch>
          {routes.map((route, index) =>
              route.private ? (
                  <PrivateRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                  >
                    <route.main />
                  </PrivateRoute>
              ) : (
                  <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={<route.main />}
                  />
              )
          )}
        </Switch>
      </Router>
  );
};

export default App;
