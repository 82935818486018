import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import {FileUploaderDialogContext} from '../contexts/FileUploaderDialogContext';
import FileUploaderForm from "./FileUploaderForm";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileUploaderDialog() {
    const {dialog, setDialog}: any = React.useContext(FileUploaderDialogContext);

    const handleClose = () => {
        setDialog({...dialog, open: false});
    };

    return (
        <Dialog
            open={dialog.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"File uploader form"}</DialogTitle>
            <DialogContent>
                <FileUploaderForm closeDialog={setDialog}/>
            </DialogContent>
        </Dialog>
    );
}
