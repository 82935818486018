import React from 'react';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Order} from '../types/Order';
import {File} from '../interfaces/File';
import dateParser from '../utils/dateParser';
import CustomTable from '../components/CustomTable';
import {FileUploaderDialogContext} from '../contexts/FileUploaderDialogContext';
import FileUploaderDialog from '../components/FileUploaderDialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import fileTableCellMapping from '../utils/fileTableCellMapping';
import {useSnackbar} from 'notistack';
import {SelectableCondition} from '../interfaces/SelectableCondition';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        toolbar: {
            padding: theme.spacing(3, 2),
        }
    }),
);

const Files = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const selectableCondition: SelectableCondition = {key: 'is_active', value: false};

    const [dialog, setDialog] = React.useState({open: false, file: null});
    const providerFileUploaderDialogState: any = React.useMemo(() => ({dialog, setDialog}), [
        dialog,
        setDialog
    ]);

    // main table variables
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof File>('name');
    const [selected, setSelected] = React.useState<number[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // create HeadCell interface for specific Entity (like ContactsHeadCell...)
    const filesCellLebels = {
        id: 'ID',
        name: 'Name',
        type_id: 'File type',
        created: 'Created Date',
        updated: 'Updated Date',
        is_active: 'Using as default',
    };

    const headCells: any[] = [
        {id: 'name', numeric: false, disablePadding: true, label: filesCellLebels['name']},
        {id: 'type_id', numeric: false, disablePadding: false, label: filesCellLebels['type_id']},
        {id: 'created', numeric: false, disablePadding: false, label: filesCellLebels['created']},
        {id: 'updated', numeric: false, disablePadding: false, label: filesCellLebels['updated']},
        {id: 'is_active', numeric: false, disablePadding: false, label: filesCellLebels['is_active']},
    ];

    const dataMapping = (data: any[]) => {
        return data.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                // TODO: need to override by using db view as data source
                type_id: item.type_id == 1 ? 'Demo' : 'Report',
                created: dateParser(item.created),
                updated: dateParser(item.updated),
                is_active: item.is_active
            };
        })
    };

    const [{data, loading}, getFiles] = useAxios({
            url: 'files',
            method: 'GET'
        },
        {manual: true}
    );

    const [{loading: updateLoading}, updateDefaultFile] = useAxios({
            url: 'files',
            method: 'PUT'
        },
        {manual: true}
    );

    const [{loading: removeLoading}, removeFiles] = useAxios({
            url: 'files',
            method: 'DELETE'
        },
        {manual: true}
    );

    const loader = () => {
        return (
            <div className={classes.root}>
                <CircularProgress/>
            </div>
        )
    };

    React.useEffect(() => {
        setRows([]);
        getFiles({
            params: {
                $skip: page * rowsPerPage,
                $top: rowsPerPage,
                $count: true,
                $orderby: `${orderBy} ${order}`,
            }
        });
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        if (!dialog.open && dialog.file) {
            getFiles({
                params: {
                    $skip: page * rowsPerPage,
                    $top: rowsPerPage,
                    $count: true,
                    $orderby: `${orderBy} ${order}`,
                }
            });
        }
    }, [dialog]);

    React.useEffect(() => {
        if (data) {
            const {count, items} = data;
            const rows: any = dataMapping(items);
            setRows(rows);
        }
    }, [data]);

    const onHandleRequestSort = (event: React.MouseEvent<unknown>, property: keyof File) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const onHandleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows
                .filter((n: any) => n[selectableCondition.key] === selectableCondition.value)
                .map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onHandleClick = (event: React.MouseEvent<unknown>, row: any) => {
        const {id} = row;
        const selectedIndex = selected.indexOf(id);
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const onHandleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onHandleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(_rowsPerPage);
        setPage(0);
    };

    const onMappedCellClick = ({id}: any) => {
        setSelected([]);
        updateDefaultFile({data: {id}})
            .then((res: any) => {
                const {msg} = res;
                enqueueSnackbar(msg ? msg : 'File was updated', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                getFiles({
                    params: {
                        $skip: page * rowsPerPage,
                        $top: rowsPerPage,
                        $count: true,
                        $orderby: `${orderBy} ${order}`,
                    }
                });
            })
            .catch((err: any) => {
                console.log('File update error: ', err);
                enqueueSnackbar('File updating failed', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const onRemoveSelectedItems = (items: any[]) => {
        setSelected([]);
        removeFiles({data: {items}})
            .then((res: any) => {
                enqueueSnackbar('Selected files were successfully deleted', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                getFiles({
                    params: {
                        $skip: page * rowsPerPage,
                        $top: rowsPerPage,
                        $count: true,
                        $orderby: `${orderBy} ${order}`,
                    }
                });
            })
            .catch((err: any) => {
                console.log('Files deleting error: ', err);
                enqueueSnackbar('Selected files deleting failed', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <FileUploaderDialogContext.Provider value={providerFileUploaderDialogState}>
            <Container>
                <CssBaseline/>
                {/*<Typography variant="button" display="block" gutterBottom>*/}
                {/*    Files*/}
                {/*</Typography>*/}
                <Paper className={classes.toolbar}>
                    <Button variant="outlined" color="primary" onClick={() => setDialog({file: null, open: true})}>
                        Add file
                    </Button>
                </Paper>
                {
                    loading ? loader() : (
                        <CustomTable
                            isSelectable={true}
                            selectableCondition={selectableCondition}
                            headCells={headCells}
                            rows={rows}
                            count={data ? data.count : 0}
                            onHandleRequestSort={onHandleRequestSort}
                            onHandleSelectAllClick={onHandleSelectAllClick}
                            onHandleClick={onHandleClick}
                            onHandleChangePage={onHandleChangePage}
                            onHandleChangeRowsPerPage={onHandleChangeRowsPerPage}
                            order={order}
                            orderBy={orderBy}
                            selected={selected}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            cellMapping={fileTableCellMapping}
                            cellMappingCallback={onMappedCellClick}
                            toolbarTitle={'Files'}
                            removeSelectedItems={onRemoveSelectedItems}
                        />
                    )
                }
            </Container>
            {dialog.open && <FileUploaderDialog/>}
        </FileUploaderDialogContext.Provider>
    )
};
export default Files;
