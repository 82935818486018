import React from 'react';
import {useParams} from 'react-router-dom';
import useAxios from 'axios-hooks';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {fileLinkBuilder} from '../utils/fileLinkBuilder';

const FileLoader = () => {
    const history = useHistory();
    const {fileToken} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [{data, error, response}, fileRequest] = useAxios({
            url: `contacts/file/${fileToken}`,
            method: 'GET',
            responseType: 'arraybuffer' // 'blob'
        },
        {manual: true}
    );

    React.useEffect(() => {
        if (fileToken) {
            fileRequest();
        }
    }, []);

    React.useEffect(() => {
        if (response) {
            fileLinkBuilder(response, 'file.pdf');
            history.push('/');
        }
    }, [data, response]);

    React.useEffect(() => {
        if (error) {
            const {data}: any = error;
            enqueueSnackbar(typeof data === 'string' ? data : 'File link has been already expired!', {
                variant: 'error',
                autoHideDuration: 4000
            });
            history.push('/');
        }
    }, [error]);

    return (
        <span>Loading file...</span>
    )
};

export default FileLoader;
