import React from 'react';
import ListIcon from '@material-ui/icons/List';

import Admin from './views/Admin';
import Login from './views/Login';
import Confirm from './views/Confirm';
import Contacts from './views/Contacts';
import Users from './views/Users';
import Files from './views/Files';
import BlockedDomain from './views/BlockedDomain';
import CommonTable from './views/CommonTable';
import FileLoader from './views/FileLoader';
import MailSettings from './views/MailSettings';

const Home = React.lazy(() => import('./views/Home'));
const Terms = React.lazy(() => import('./views/Terms'));
const Privacy = React.lazy(() => import('./views/Privacy'));
const Transaction = React.lazy(() => import('./views/Transaction'));

const routes = [
  {
    path: '/buy/',
    exact: true,
    main: Transaction,
    key: 'Transactioin',
    private: false
  },
  {
    path: '/confirm/:id',
    exact: true,
    main: Confirm,
    key: 'Confirm',
    private: false
  },
  {
    path: '/confirm/file/:fileToken',
    exact: true,
    main: FileLoader,
    key: 'File loader',
    private: false
  },
  {
    path: '/login',
    exact: true,
    main: Login,
    key: 'Login',
    private: false
  },
  {
    path: '/admin',
    exact: false,
    main: Admin,
    key: 'Admin',
    private: true,
    children: [
      {
        path: '/contacts',
        exact: false,
        main: Contacts,
        key: 'Contacts',
        private: true,
        icon: (color: any) => <ListIcon color={color} />
      },
      {
        path: '/files',
        exact: false,
        main: Files,
        key: 'Uploaded files',
        private: true,
        icon: (color: any) => <ListIcon color={color} />
      },
      {
        path: '/blocked',
        exact: false,
        main: BlockedDomain,
        key: 'Domain blocked list',
        private: true,
        icon: (color: any) => <ListIcon color={color} />
      },
      {
        path: '/mail-settings',
        exact: false,
        main: MailSettings,
        key: 'Email settings',
        private: true,
        icon: (color: any) => <ListIcon color={color} />
      }
      // {
      //     path: '/users',
      //     exact: false,
      //     main: Users,
      //     key: 'System users',
      //     private: true,
      //     icon: (color: any) => <ListIcon color={color} />
      // },
    ]
  },

  {
    path: '/',
    exact: true,
    main: Home,
    key: 'Main',
    private: false
  },

  {
    path: '/terms',
    exact: true,
    main: Terms,
    key: 'Terms',
    private: false
  },

  {
    path: '/privacy',
    exact: true,
    main: Privacy,
    key: 'Privacy',
    private: false
  },
];
export default routes;
