import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import {DomainDialogContext} from '../contexts/DomainDialogContext';
import DomainForm from './DomainForm';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DomainDialog() {
    const [title, setTitle] = React.useState('Add domain to the Blocked list');
    const {dialog, setDialog}: any = React.useContext(DomainDialogContext);

    React.useEffect(() => {
        if (dialog && dialog.domain) {
            setTitle('Edit domain');
        }
    }, [dialog]);

    const handleClose = () => {
        setDialog({...dialog, open: false});
    };

    return (
        <Dialog
            open={dialog.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DomainForm closeDialog={setDialog} domain={dialog.domain}/>
            </DialogContent>
        </Dialog>
    );
}
