import React from 'react';
import clsx from 'clsx';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import {EnhancedTableToolbarProps} from '../interfaces/EnhancedTableToolbarProps';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PrintIcon from '@material-ui/icons/Print';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

// EnhancedTableToolbar
const CustomTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const {numSelected, title, onRemoveItems, selected, printable, onPrintItems, refreshable, onRefreshItems} = props;

    const removeSelected = () => onRemoveItems(selected);
    const print = () => onPrintItems(true);

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle">
                    {title}
                </Typography>
            )}
            {/* uncomment to use filter */}
            {/*{numSelected > 0 ? (*/}
            {/*    <Tooltip title="Delete">*/}
            {/*        <IconButton aria-label="delete">*/}
            {/*            <DeleteIcon/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*) : (*/}
            {/*    <Tooltip title="Filter list">*/}
            {/*        <IconButton aria-label="Filter list">*/}
            {/*            <FilterListIcon/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*)}*/}
            {numSelected > 0 && (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={removeSelected}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }
            {
                (printable && !numSelected) && (
                    <Tooltip title="Print list">
                        <IconButton aria-label="print list" onClick={print}>
                            <PrintIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }
            {
                (refreshable) && (
                    <Tooltip title="Refresh">
                        <IconButton aria-label="refresh" onClick={onRefreshItems}>
                            <AutorenewIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
        </Toolbar>
    );
};

export default CustomTableToolbar;
