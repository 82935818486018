import Axios from 'axios';

const axios = Axios.create({
  baseURL: '/api/' // prod
  // baseURL: 'http://localhost:3000/api/' //  dev
});

axios.interceptors.request.use(
  config => {
    try {
      const { url } = config;
      config.headers['content-type'] =
        config.headers['content-type'] ||
        config.headers['Content-Type'] ||
        'application/json';
      if (
        url === 'contacts' ||
        url === 'files' ||
        url === 'users' ||
        url === 'blocked' ||
        url === 'mail-settings'
      ) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `bearer ${token}`;
        } else {
          localStorage.removeItem('token');
          delete config.headers['Authorization'];
        }
      }
      return config;
    } catch (error) {
      console.log('Request error', error);
      return Promise.reject(error);
    }
  },
  error => {
    // handle the error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.hasOwnProperty('response')) {
      if (error.response && error.response.status) {
        if (error.response.status === 401) {
          console.log('unauthorized, logging out ...');
          localStorage.removeItem('token');
          return Promise.reject(
            error.response.data || error.message || 'Помилка серверу'
          );
        }
        if (error.response.status === 404) {
          return Promise.reject(
            error.response.data || error.message || 'Помилка серверу'
          );
        }
      }
    }
    return Promise.reject(error.response || error.message || 'Помилка серверу');
  }
);

export default axios;
