import React from 'react';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {Order} from '../types/Order';
import {Domain} from '../interfaces/Domain';
import dateParser from '../utils/dateParser';
import CustomTable from '../components/CustomTable';
import {DomainDialogContext} from '../contexts/DomainDialogContext';
import DomainDialog from '../components/DomainDialog';
import domainTableCellMapping from '../utils/domainTableCellMapping';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        toolbar: {
            padding: theme.spacing(3, 2),
        }
    }),
);

const BlockedDomain = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [dialog, setDialog] = React.useState({open: false, domain: null});
    const providerDomainDialogState: any = React.useMemo(() => ({dialog, setDialog}), [
        dialog,
        setDialog
    ]);

    // main table variables
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Domain>('name');
    const [selected, setSelected] = React.useState<number[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const domainCellLebels = {
        id: 'ID',
        actions: 'Actions',
        name: 'Name',
        description: 'Description',
        created: 'Created Date',
        updated: 'Updated Date',
    };

    const headCells: any[] = [
        {id: 'actions', numeric: false, disablePadding: true, label: domainCellLebels['actions']},
        {id: 'name', numeric: false, disablePadding: true, label: domainCellLebels['name']},
        {id: 'description', numeric: false, disablePadding: false, label: domainCellLebels['description']},
        {id: 'created', numeric: false, disablePadding: false, label: domainCellLebels['created']},
        {id: 'updated', numeric: false, disablePadding: false, label: domainCellLebels['updated']},
    ];

    const dataMapping = (data: any[]) => {
        return data.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                description: item.description,
                created: dateParser(item.created),
                updated: dateParser(item.updated),
            };
        })
    };

    const [{data, loading}, getDomains] = useAxios({
            url: 'blocked',
            method: 'GET'
        },
        {manual: true}
    );

    const [{loading: updateLoading}, updateDomain] = useAxios({
            url: 'blocked',
            method: 'PUT'
        },
        {manual: true}
    );

    const [{loading: removeLoading}, removeDomains] = useAxios({
            url: 'blocked',
            method: 'DELETE'
        },
        {manual: true}
    );

    const loader = () => {
        return (
            <div className={classes.root}>
                <CircularProgress/>
            </div>
        )
    };

    React.useEffect(() => {
        setRows([]);
        getDomains({
            params: {
                $skip: page * rowsPerPage,
                $top: rowsPerPage,
                $count: true,
                $orderby: `${orderBy} ${order}`,
            }
        });
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        if (!dialog.open && dialog.domain) {
            getDomains({
                params: {
                    $skip: page * rowsPerPage,
                    $top: rowsPerPage,
                    $count: true,
                    $orderby: `${orderBy} ${order}`,
                }
            });
        }
    }, [dialog]);

    React.useEffect(() => {
        if (data) {
            const {count, items} = data;
            const rows: any = dataMapping(items);
            setRows(rows);
        }
    }, [data]);

    const onHandleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Domain) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const onHandleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onHandleClick = (event: React.MouseEvent<unknown>, row: any) => {
        const {id} = row;
        const selectedIndex = selected.indexOf(id);
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const onHandleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onHandleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onMappedCellClick = (data: any) => {
        setDialog({domain: data, open: true});
    };

    const onRemoveSelectedItems = (items: any[]) => {
        setSelected([]);
        removeDomains({data: {items}})
            .then((res: any) => {
                enqueueSnackbar('Selected domains were successfully deleted', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                getDomains({
                    params: {
                        $skip: page * rowsPerPage,
                        $top: rowsPerPage,
                        $count: true,
                        $orderby: `${orderBy} ${order}`,
                    }
                });
            })
            .catch((err: any) => {
                console.log('Domains deleting error: ', err);
                enqueueSnackbar('Selected domains deleting failed', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <DomainDialogContext.Provider value={providerDomainDialogState}>
            <Container>
                <CssBaseline/>
                <Paper className={classes.toolbar}>
                    <Button variant="outlined" color="primary" onClick={() => setDialog({domain: null, open: true})}>
                        Add domain
                    </Button>
                </Paper>
                {
                    loading ? loader() : (
                        <CustomTable
                            isSelectable={true}
                            headCells={headCells}
                            rows={rows}
                            count={data ? data.count : 0}
                            onHandleRequestSort={onHandleRequestSort}
                            onHandleSelectAllClick={onHandleSelectAllClick}
                            onHandleClick={onHandleClick}
                            onHandleChangePage={onHandleChangePage}
                            onHandleChangeRowsPerPage={onHandleChangeRowsPerPage}
                            order={order}
                            orderBy={orderBy}
                            selected={selected}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            toolbarTitle={'Blocked domains list'}
                            removeSelectedItems={onRemoveSelectedItems}
                            cellMapping={domainTableCellMapping}
                            cellMappingCallback={onMappedCellClick}
                        />
                    )
                }
            </Container>
            {dialog.open && <DomainDialog/>}
        </DomainDialogContext.Provider>
    )
};

export default BlockedDomain;
