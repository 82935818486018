import React from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import CustomTable from '../components/CustomTable';
import dateParser from '../utils/dateParser';
import {Contact} from '../interfaces/Contact';
import {Order} from '../types/Order';
import contactsTableCellMapping from '../utils/contactsTableCellMapping';
import {useSnackbar} from 'notistack';
import {fileLinkBuilder} from '../utils/fileLinkBuilder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        toolbar: {
            padding: theme.spacing(3, 2),
        }
    }),
);

const Contacts = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    // main table variables
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Contact>('name');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // create HeadCell interface for specific Entity (like ContactsHeadCell...)
    const contactsCellLebels = {
        id: 'ID',
        name: 'Name',
        email: 'Email',
        created: 'Created Date',
        requested_file: 'File',
        message: 'Message',
        is_subscribed: 'Subscribed',
        requested_file_date: 'File request date',
        is_demo_requested: 'Demo',
        demo_requested_date: 'Demo request date'
    };

    // was > const headCells: HeadCell[]
    const headCells: any[] = [
        {id: 'name', numeric: false, disablePadding: true, label: contactsCellLebels['name']},
        {id: 'email', numeric: false, disablePadding: false, label: contactsCellLebels['email']},
        {id: 'created', numeric: false, disablePadding: false, label: contactsCellLebels['created']},
        {id: 'requested_file_date', numeric: false, disablePadding: false, label: contactsCellLebels['requested_file_date']},
        {id: 'is_demo_requested', numeric: false, disablePadding: false, label: contactsCellLebels['is_demo_requested']},
        {id: 'demo_requested_date', numeric: false, disablePadding: false, label: contactsCellLebels['demo_requested_date']},
        {id: 'is_subscribed', numeric: false, disablePadding: false, label: contactsCellLebels['is_subscribed']},
        {id: 'message', numeric: false, disablePadding: false, label: contactsCellLebels['message']},
    ];

    const dataMapping = (data: any[]) => {
        return data.map((item: any) => {
            return {
                id: item.id,
                name: item.name,
                email: item.email,
                created: dateParser(item.created),
                requested_file_date: dateParser(item.requested_file_date),
                is_demo_requested: item.is_demo_requested,
                demo_requested_date: dateParser(item.demo_requested_date),
                is_subscribed: item.is_subscribed,
                message: item.message
            };
        })
    };

    const [{data, loading}, getContacts] = useAxios({
            url: 'contacts',
            method: 'GET'
        },
        {manual: true}
    );

    const [{loading: deleteLoading}, deleteContacts] = useAxios({
            url: 'contacts',
            method: 'DELETE'
        },
        {manual: true}
    );

    const [{
        data: printedData,
        error: printedError,
        loading: printLoading,
        response: printedResponse
    }, print] = useAxios({
            url: 'contacts/print',
            method: 'GET'
        },
        {manual: true}
    );

    const loader = () => {
        return (
            <div className={classes.root}>
                <CircularProgress/>
            </div>
        )
    };

    React.useEffect(() => {
        setRows([]);
        getContacts({
            params: {
                $skip: page * rowsPerPage,
                $top: rowsPerPage,
                $count: true,
                $orderby: `${orderBy} ${order}`,
            }
        });
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        if (data) {
            const {count, items} = data;
            const rows: any = dataMapping(items);
            setRows(rows);
        }
    }, [data]);

    const onHandleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Contact) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const onHandleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: any) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onHandleClick = (event: React.MouseEvent<unknown>, row: any) => {
        const {id} = row;
        const selectedIndex = selected.indexOf(id);
        let newSelected: any[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const onHandleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onHandleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(_rowsPerPage);
        setPage(0);
    };

    const onRemoveSelectedItems = (items: any[]) => {
        setSelected([]);
        deleteContacts({data: {items}})
            .then((res: any) => {
                enqueueSnackbar('Selected contacts were successfully deleted', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                getContacts({
                    params: {
                        $skip: page * rowsPerPage,
                        $top: rowsPerPage,
                        $count: true,
                        $orderby: `${orderBy} ${order}`,
                    }
                });
            })
            .catch((err: any) => {
                console.log('Contacts deleting error: ', err);
                enqueueSnackbar('Selected contacts deleting failed', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const onPrintItems = (event: any) => {
        print({
            params: {
                $orderby: `${orderBy} ${order}`,
            }
        });
    };

    const onRefreshItems = () => {
        getContacts({
            params: {
                $skip: page * rowsPerPage,
                $top: rowsPerPage,
                $count: true,
                $orderby: `${orderBy} ${order}`,
            }
        });
    };

    React.useEffect(() => {
        if (printedResponse) {
            fileLinkBuilder(printedResponse, 'contacts.csv');
        }
    }, [printedResponse]);

    React.useEffect(() => {
        if (printedError) {
            enqueueSnackbar('Data export failed', {
                variant: 'error',
                autoHideDuration: 3000
            });
        }
    }, [printedError]);

    return (
        <Container>
            <CssBaseline/>
            {
                loading ? loader() : (
                    <CustomTable
                        isSelectable={true}
                        headCells={headCells}
                        rows={rows}
                        count={data ? data.count : 0}
                        onHandleRequestSort={onHandleRequestSort}
                        onHandleSelectAllClick={onHandleSelectAllClick}
                        onHandleClick={onHandleClick}
                        onHandleChangePage={onHandleChangePage}
                        onHandleChangeRowsPerPage={onHandleChangeRowsPerPage}
                        order={order}
                        orderBy={orderBy}
                        selected={selected}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        toolbarTitle={'Contacts'}
                        cellMapping={contactsTableCellMapping}
                        removeSelectedItems={onRemoveSelectedItems}
                        printable={true}
                        printItems={onPrintItems}
                        refreshable={true}
                        refreshItems={onRefreshItems}
                    />
                )
            }
        </Container>
    )
};
export default Contacts;
