import React from 'react';
import {Guid} from 'guid-typescript';
import TableCell from '@material-ui/core/TableCell';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';

const domainTableCellMapping = (row: any, isSelectable: boolean, cellClick: any) => {
    return Object.keys(row).map((cell, index) => {
        const uniqueKey = Guid.create().toString();
        // action cell
        if (index === 0) {
            return <TableCell key={uniqueKey}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="Edit domain"
                    onClick={() => cellClick(row)}
                >
                    <EditIcon />
                </Fab>
            </TableCell>;
        }
        const startCell =
            <TableCell
                key={uniqueKey}
                component="th"
                scope="row"
                padding={isSelectable ? "none" : "default"}>
                <span>{row.name}</span>
            </TableCell>;
        const simpleCell = <TableCell align="left" key={uniqueKey}><span>{row[cell]}</span></TableCell>;
        return index === 1 ? startCell : simpleCell;
    });
};
export default domainTableCellMapping;
