import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Grid from '@material-ui/core/Grid';
import {indigo} from '@material-ui/core/colors';
import {DrawerContext} from '../contexts/DrawerContext';
import {drawerConfig} from '../config/drawer';
import routes from '../routes';
import {Link, useLocation, useRouteMatch} from 'react-router-dom';
import clsx from 'clsx';

const drawerWidth = drawerConfig.width;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    avatarDiv: {
        backgroundImage: 'url(' + require('../assets/images/material_bg.png') + ')',
    },
    avatarIcon: {
        float: 'left',
        display: 'block',
        marginRight: 15,
        boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)'
    },
    avatarSpan: {
        paddingTop: 12,
        display: 'block',
        color: 'white',
        fontWeight: 300,
        textShadow: '1px 1px #444'
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: indigo[500],
    },
    link: {
        margin: theme.spacing(1)
    },
    activeLink: {
        backgroundColor: theme.palette.grey['200'] || '#fafafa'
    },
    activeLinkIcon: {
        color: indigo.toString()
    },
    listItemText: {
        fontSize: '1em'
    }
}));

export default function DrawerBar() {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    let { url } = useRouteMatch();

    const {open, setOpen}: any = React.useContext(DrawerContext);
    const handleDrawerChange = () => setOpen(!open);

    const [admin] = routes
        .filter(route => route.key === 'Admin');
    const adminRoutes = admin.children || [];

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.avatarDiv}>
                <Avatar className={classes.greenAvatar}>
                    <AssignmentIcon/>
                </Avatar>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerChange}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </div>
            </Grid>
            <Divider/>
            <List>
                {adminRoutes
                    .map((route) => (
                        <ListItem
                            button
                            key={route.key}
                            component={Link}
                            to={`${url}${route.path}`}
                            className={clsx({
                                [classes.activeLink]: location.pathname === url + route.path
                            })}
                        >
                            {route.icon && <ListItemIcon>
                                {route.icon(
                                    location.pathname === url + route.path ? 'primary' : 'disabled'
                                )}
                            </ListItemIcon>}
                            <ListItemText
                                primary={route.key}
                                classes={{primary: classes.listItemText}}
                            />
                        </ListItem>
                    ))}
            </List>
        </Drawer>
    )
}
