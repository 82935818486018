import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export const PrivateRoute = ({children, ...rest}: any) => {
    const isAuthenticated = localStorage.getItem('token');

    return (
        <Route
            {...rest}
            render={({location}: any) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
};
