import React from 'react';

type Notify = {
    message: string | React.ReactNode,
    variant?: 'default' | 'error' | 'success' | 'warning' | 'info',
    autoHideDuration?: number
}

const setNotifyInfo = ({message = 'Info', variant = 'info', autoHideDuration = 2000}: Notify) => {
    return {
        message,
        variant,
        autoHideDuration,
        preventDuplicate: true
    }
};

const setNotifySuccess = ({message = 'Success', variant = 'success', autoHideDuration = 2000}: Notify) => {
    return {
        message,
        variant,
        autoHideDuration,
        preventDuplicate: true
    }
};

const setNotifyError = ({message = 'Error', variant = 'error', autoHideDuration = 2000}: Notify) => {
    return {
        message,
        variant,
        autoHideDuration,
        preventDuplicate: true
    }
};

export {
    setNotifyInfo,
    setNotifySuccess,
    setNotifyError
}
