const dateParser = (date: any) => {
    let result = '';
    if (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        const year = date.getFullYear() + '';
        const month = (date.getMonth() + 1 + '').padStart(2, '0');
        const day = (date.getDate() + '').padStart(2, '0');

        const hours = (date.getHours() + '').padStart(2, '0');
        const minutes = (date.getMinutes() + '').padStart(2, '0');
        const seconds = (date.getSeconds() + '').padStart(2, '0');

        result = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
    return result;
};

export default dateParser;
