import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import {Guid} from 'guid-typescript';
import Checkbox from '@material-ui/core/Checkbox';

const customTableCellMapping = (row: any, isSelectable: boolean, callback?: any) => {
    return Object.keys(row).map((cell, index) => {
        const uniqueKey = Guid.create().toString();
        const startCell =
            <TableCell
                key={uniqueKey}
                component="th"
                scope="row"
                padding={isSelectable ? "none" : "default"}>
                <span>{row.name}</span>
            </TableCell>;

        let simpleCell = <TableCell align="left" key={uniqueKey}><span>{row[cell]}</span></TableCell>;
        if (typeof row[cell] === 'boolean') {
            simpleCell = <TableCell align="left" key={uniqueKey}>
                <Checkbox checked={row[cell]} disabled={true}/>
            </TableCell>
        }

        return index === 0 ? startCell : simpleCell;
    });
};
export default customTableCellMapping;
