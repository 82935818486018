import React from 'react';
import useForm from 'react-hook-form';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import axios from '../core/setAxiosOptions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        button: {
            margin: theme.spacing(1, 1, 1),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
        input: {
            display: 'none',
        },
    })
);

type FileUploaderProps = {
    closeDialog: any;
}

export default function FileUploaderForm({closeDialog}: FileUploaderProps) {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [file, setFile] = React.useState('');
    const [filename, setFilename] = React.useState('');

    const handleCloseDialog = ({id}: any) => {
        closeDialog({file: id, open: false});
    };

    // file types
    const [{data: types}, getFileTypes] = useAxios({
            url: 'file/types',
            method: 'GET'
        },
        {manual: true}
    );
    const memoizedTypesRequest = React.useCallback(() => getFileTypes(), []);

    const [{data: oploads}, uploadFile] = useAxios({
            url: 'files',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        },
        {
            manual: true,
        }
    );

    // main form props
    const {register, handleSubmit, errors, setValue} = useForm();

    React.useEffect(() => {
        memoizedTypesRequest();
        // type selection temporary disabled
        // register({name: 'types'}, {required: true});
    }, []);

    const onChange = (event: any) => {
        setFile(event.target.files[0]);
        setFilename(event.target.files[0].name);
    };

    // expected param in case when type selection is in use => {types: [type]}: any
    const onSubmit = () => {
        if (!file && !filename) {
            enqueueSnackbar(`There is no file selected`, {
                variant: 'error',
                autoHideDuration: 3000
            });
        } else {
            const fileData = new FormData();
            // fileData.set('type', type);
            fileData.set('type', '2'); // report type is a default value
            fileData.append('file', file, filename);
            axios({
                method: 'POST',
                url: 'files',
                data: fileData,
                headers: {'Content-Type': 'multipart/form-data' }
            })
                .then((response: any) => {
                    enqueueSnackbar(`${filename} was uploaded successfully`, {
                        variant: 'success',
                        autoHideDuration: 3000
                    });
                    handleCloseDialog(response.hasOwnProperty('data') ? response.data : response);
                })
                .catch((error: any) => {
                    console.log(error);
                    enqueueSnackbar(`Failed to upload ${filename}`, {
                        variant: 'error',
                        autoHideDuration: 3000
                    });
                });
        }
    };

    const handleTypeSelectChange = (types: any) => {
        setValue('types', [types.target.value]);
    };

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <TextField
                        disabled
                        margin="dense"
                        id="outlined-disabled"
                        label="File"
                        defaultValue="No file selected"
                        value={filename}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <input className={classes.input} id="icon-button-file" type="file" onChange={onChange} />
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="select file" component="span">
                            <PostAddIcon fontSize="large" />
                        </IconButton>
                    </label>
                </Grid>
                {/* Notice: temporary only one type of files available. Report only. */}
                {/*<Grid item xs={12}>*/}
                {/*    <TextField*/}
                {/*        id="outlined-select-type"*/}
                {/*        select*/}
                {/*        label="Set file type"*/}
                {/*        name="types"*/}
                {/*        margin="dense"*/}
                {/*        variant="outlined"*/}
                {/*        fullWidth*/}
                {/*        error={errors.hasOwnProperty('types')}*/}
                {/*        required*/}
                {/*        className={classes.textField}*/}
                {/*        onChange={handleTypeSelectChange}*/}
                {/*    >*/}
                {/*        {types && types.map((option: any) => (*/}
                {/*            <MenuItem key={option.id} value={option.id}>*/}
                {/*                {option.name}*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </TextField>*/}
                {/*</Grid>*/}
            </Grid>
            <DialogActions>
                <Button
                    onClick={handleCloseDialog}
                    color="secondary"
                    className={classes.button}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    className={classes.button}
                >
                    Upload file
                </Button>
            </DialogActions>
        </form>
    )
}
