import React from 'react';
import {useParams} from 'react-router-dom';
import useAxios from 'axios-hooks';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';

const Confirm = () => {
    const history = useHistory();
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [{data, error, response}, confirmRequest] = useAxios({
            url: `contacts/confirm/${id}`,
            method: 'GET'
        },
        {manual: true}
    );

    React.useEffect(() => {
        if (id) {
            confirmRequest();
        }
    }, []);

    React.useEffect(() => {
        if (response) {
            enqueueSnackbar(typeof data === 'string' ? data : 'Now you can check your mailbox', {
                variant: 'success',
                autoHideDuration: 3000
            });
            history.push('/');
        }
    }, [data, response]);

    React.useEffect(() => {
        if (error) {
            const {data}: any = error;
            enqueueSnackbar(typeof data === 'string' ? data : 'Confirm failed!', {
                variant: 'error',
                autoHideDuration: 3000
            });
            history.push('/');
        }
    }, [error]);

    return (
        <span>Confirming...</span>
    )
};

export default Confirm;
