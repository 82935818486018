export const drawerConfig = {
    width: 240,
};

export const config = {
    trakingId: 'UA-153844188-1',
    hjid: '1599534',
    hjsv: '6',
    siteKey: '6LeOpRMcAAAAANcpFwn9rzFzJl1ZoXEPWGi7ze1h',
};

export const currentSiteKey = () => {
    return config.siteKey;
};
