import React from 'react';
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EmailContentForm from '../components/EmailContentForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            paddingBottom: theme.spacing(2),
        },
        form: {
            paddingTop: theme.spacing(2),
            padding: theme.spacing(3, 2),
        }
    }),
);

const MailSettings = () => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Container>
            <Paper className={classes.root}>
                <Typography variant="h5" component="h3">
                    Email settings
                </Typography>
                <Typography component="p">
                    Here you can configure a file response email content.
                </Typography>
                <EmailContentForm />
            </Paper>
        </Container>
    )
};

export default MailSettings;
