import React from 'react';
import useForm from 'react-hook-form';
import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      marginTop: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1, 1, 1)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2)
    }
  })
);

type DomainFormProps = {
  closeDialog: any;
  domain?: any;
};

export default function DomainForm({ closeDialog, domain }: DomainFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDialog = ({ id }: any) => {
    closeDialog({ domain: id, open: false });
  };

  const [{ data: createdData, error: createError }, createDomain] = useAxios(
    {
      url: 'blocked',
      method: 'POST'
    },
    {
      manual: true
    }
  );
  const [{ data: updatedData, error: updateError }, updateDomain] = useAxios(
    {
      url: 'blocked',
      method: 'PUT'
    },
    {
      manual: true
    }
  );

  // main form props
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => {
    if (domain) {
      updateDomain({ data: { id: domain.id, ...data } })
        .then((response: any) => {
          enqueueSnackbar(`Domain was updated successfully`, {
            variant: 'success',
            autoHideDuration: 3000
          });
          handleCloseDialog(
            response.hasOwnProperty('data') ? response.data : response
          );
        })
        .catch((error: any) => {
          let message = `Failed to update current domain`;
          if (error && error.hasOwnProperty('data')) {
            message = error.data;
          }
          enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
          });
        });
    } else {
      createDomain({ data })
        .then((response: any) => {
          enqueueSnackbar(`Domain was created successfully`, {
            variant: 'success',
            autoHideDuration: 3000
          });
          handleCloseDialog(
            response.hasOwnProperty('data') ? response.data : response
          );
        })
        .catch((error: any) => {
          let message = `Failed to update current domain`;
          if (error && error.hasOwnProperty('data')) {
            message = error.data;
          }
          enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
          });
        });
    }
  };

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            defaultValue={domain ? domain.name : 'domain.com'}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            inputRef={register({
              required: domain ? false : true,
              maxLength: {
                value: 255,
                message: 'To long value'
              }
            })}
            disabled={!!domain}
            error={errors.hasOwnProperty('name')}
            helperText={
              errors.name ? errors.name.message : 'Example: strangeDomain.com'
            }
          />
        </Grid>
        <Grid item xs={12}>
          {/*<TextField*/}
          {/*    id="description"*/}
          {/*    name="description"*/}
          {/*    label="Description"*/}
          {/*    defaultValue="this domain is useless"*/}
          {/*    className={classes.textField}*/}
          {/*    margin="normal"*/}
          {/*    variant="outlined"*/}
          {/*    fullWidth*/}
          {/*    inputRef={register({*/}
          {/*        maxLength: {*/}
          {/*            value: 255,*/}
          {/*            message: 'To long value'*/}
          {/*        }*/}
          {/*    })}*/}
          {/*    error={errors.hasOwnProperty('description')}*/}
          {/*    helperText={errors.description && errors.description.message}*/}
          {/*/>*/}

          <TextField
            id="description"
            name="description"
            label="Description"
            defaultValue={
              domain ? domain.description : 'this domain is useless'
            }
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            rows="4"
            inputRef={register({
              maxLength: {
                value: 255,
                message: 'To long value'
              }
            })}
            error={errors.hasOwnProperty('description')}
            helperText={errors.description && errors.description.message}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          color="secondary"
          className={classes.button}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" className={classes.button}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
}
