import React from 'react';
import clsx from 'clsx';
import {DrawerContext} from '../contexts/DrawerContext';
import {drawerConfig} from '../config/drawer';
import Header from '../components/Header';
import DrawerBar from '../components/DrawerBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
// admin routing
import {Switch, useRouteMatch} from 'react-router-dom';
import routes from '../routes';
import {PrivateRoute} from '../utils/PrivateRoute';

const drawerWidth = drawerConfig.width;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

export default function Admin() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const drawerState: any = React.useMemo(() => ({open, setOpen}), [open, setOpen]);

    let {path} = useRouteMatch();

    const [admin] = routes
        .filter(route => route.key === 'Admin');
    const adminRoutes: any = admin.children || [];

    return (
        <DrawerContext.Provider value={drawerState}>
            <div className={classes.root}>
                <CssBaseline/>
                <Header/>
                <DrawerBar/>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader}/>
                    <Switch>
                        {
                            adminRoutes.map((route: any, index: number) => (
                                <PrivateRoute
                                    key={index}
                                    path={`${path}${route.path}`}
                                    exact={route.exact}
                                >
                                    <route.main/>
                                </PrivateRoute>
                            ))
                        }
                    </Switch>
                </main>
            </div>
        </DrawerContext.Provider>
    )
}
