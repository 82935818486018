import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {stableSort, getSorting} from '../utils/CustomTableSorting';
import CustomTableHead from './CustomTableHead';
import CustomTableToolbar from './CustomTableToolbar';
import customTableCellMapping from '../utils/customTableCellMapping';
import {Guid} from 'guid-typescript';
import {SelectableCondition} from "../interfaces/SelectableCondition";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

export default function CustomTable(props: any) {
    const {
        headCells,
        rows,
        count,
        isSelectable,
        selectableCondition = true,
        onHandleRequestSort,
        onHandleSelectAllClick,
        onHandleClick,
        onHandleChangePage,
        onHandleChangeRowsPerPage,
        order,
        orderBy,
        selected,
        page,
        rowsPerPage,
        cellMapping = customTableCellMapping,
        cellMappingCallback = () => {},
        toolbarTitle,
        removeSelectedItems,
        printable = false,
        printItems = () => {},
        refreshable = false,
        refreshItems = () => {}
    } = props;

    const classes = useStyles();

    const checkSelectableCondition = (row: any, selectableCondition: SelectableCondition | boolean) => {
        if (typeof selectableCondition === 'object') {
            return row[selectableCondition.key] === selectableCondition.value;
        } else {
            return selectableCondition;
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        onHandleRequestSort(event, property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        onHandleSelectAllClick(event);
    };

    const handleClick = (event: React.MouseEvent<unknown>, row: any, selectableConditionPassed: boolean) => {
        if (isSelectable && selectableConditionPassed) {
            onHandleClick(event, row);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onHandleChangePage(event, newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onHandleChangeRowsPerPage(event);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const availableToSelectionRowsCount = (rows: any[]) => {
        return rows.filter((row: any) => checkSelectableCondition(row, selectableCondition)).length;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <CustomTableToolbar
                    numSelected={selected.length}
                    title={toolbarTitle}
                    selected={selected}
                    onRemoveItems={removeSelectedItems}
                    printable={printable}
                    onPrintItems={printItems}
                    refreshable={refreshable}
                    onRefreshItems={refreshItems}
                />
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <CustomTableHead
                            headCells={headCells}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            // need to pass available count of rows to be select
                            // rowCount={rows.length}
                            rowCount={availableToSelectionRowsCount(rows)}
                            isSelectable={isSelectable}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row: any, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            // onClick={event => handleClick(event, row, checkSelectableCondition(row, selectableCondition))}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={Guid.create().toString()}
                                            selected={isItemSelected}
                                        >
                                            {
                                                (isSelectable) && (<TableCell padding="checkbox">
                                                    {checkSelectableCondition(row, selectableCondition) && <Checkbox
                                                        onClick={event => handleClick(event, row, checkSelectableCondition(row, selectableCondition))}
                                                        checked={isItemSelected}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />}
                                                </TableCell>)
                                            }
                                            {
                                                cellMapping(row, isSelectable, cellMappingCallback)
                                            }
                                        </TableRow>
                                    );
                                })}
                            {/*{emptyRows > 0 && (*/}
                            {/*    // style={{height: 53 * emptyRows}}*/}
                            {/*    <TableRow style={{height: 53}}>*/}
                            {/*        <TableCell colSpan={6}/>*/}
                            {/*    </TableRow>*/}
                            {/*)}*/}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};
