import React from 'react';
import useForm from 'react-hook-form';
import useAxios from 'axios-hooks';
import {useSnackbar} from 'notistack';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
    })
);

const EmailContentForm = () => {
    const initialSettings = {
        title: 'Title',
        content: 'Content',
        description: 'Description',
        button: 'Button',
    };
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const {register, unregister, handleSubmit, errors, setError, clearError, setValue} = useForm({
        defaultValues: initialSettings
    });

    const [{data, loading, error}, getMailSettings] = useAxios(
        {
            url: 'mail-settings',
            method: 'GET'
        },
        {
            manual: true
        }
    );

    const [{
        data: updatedData,
        loading: updating,
        error: updatedError
    }, updateMailSettings] = useAxios(
        {
            url: 'mail-settings',
            method: 'PUT'
        },
        {
            manual: true
        }
    );

    const updateFormControl = (key: string, value: number) => {
        if (value) setValue(key, value);
    };

    const handleTitleChange = (event: any) => {
        const key = 'title';
        const value = event.target.value;
        if (!value || !value.trim()) return setError(key, 'required', 'Value is required');
        if (value && value.length > 255) return setError(key, 'max', 'To long value. Max is 255');
        clearError(key);
        setValue(key, value);
    };

    const handleContentChange = (event: any) => {
        const key = 'content';
        const value = event.target.value;
        if (!value || !value.trim()) return setError(key, 'required', 'Value is required');
        if (value && value.length > 1024) return setError(key, 'max', 'To long value. Max is 1024');
        clearError(key);
        setValue(key, value);
    };

    const handleDescriptionChange = (event: any) => {
        const key = 'description';
        const value = event.target.value;
        if (!value || !value.trim()) return setError(key, 'required', 'Value is required');
        if (value && value.length > 255) return setError(key, 'max', 'To long value. Max is 255');
        clearError(key);
        setValue(key, value);
    };

    const handleButtonChange = (event: any) => {
        const key = 'button';
        const value = event.target.value;
        if (!value || !value.trim()) return setError(key, 'required', 'Value is required');
        if (value && value.length > 32) return setError(key, 'max', 'To long value. Max is 32');
        clearError(key);
        setValue(key, value);
    };

    React.useEffect(() => {
        // unregister input after component unmount
        return () => {
            unregister('title');
            unregister('content');
            unregister('description');
            unregister('button');
        }
    }, [register]);

    React.useEffect(() => {
        if (data) {
            const {id, ...settings} = data;
            for (const key in settings) {
                if (settings.hasOwnProperty(key)) {
                    updateFormControl(key, settings[key]);
                }
            }
        }
    }, [data]);

    React.useEffect(() => {
        getMailSettings();
    }, [updatedData]);

    const onSubmit = (data: any) => {
        updateMailSettings({ data })
            .then(response => {
                let message = 'Email content was updated successfully';
                if (response && response.hasOwnProperty('data') && response.data.hasOwnProperty('msg')) {
                    message = response.data.msg;
                }
                enqueueSnackbar(message, {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            })
            .catch(error => {
                let message = `Failed to update current domain`;
                if (error && error.hasOwnProperty('data')) {
                    message = error.data;
                }
                enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    return (
        <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        inputRef={register({
                            required: true
                        })}
                        onChange={handleTitleChange}
                        error={errors.hasOwnProperty('title')}
                        helperText={
                            errors.title ? errors.title.message : 'Example: Report'
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="content"
                        name="content"
                        label="Content"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="4"
                        required
                        inputRef={register({
                            required: true
                        })}
                        onChange={handleContentChange}
                        error={errors.hasOwnProperty('content')}
                        helperText={
                            errors.content ? errors.content.message : 'Example: This email has a file'
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="description"
                        name="description"
                        label="Description"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows="4"
                        required
                        inputRef={register({
                            required: true
                        })}
                        onChange={handleDescriptionChange}
                        error={errors.hasOwnProperty('description')}
                        helperText={
                            errors.description ? errors.description.message : 'Example: Click button below to upload a file'
                        }
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={4}>
                        <TextField
                            id="button"
                            name="button"
                            label="Button title"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            required
                            inputRef={register({
                                required: true
                            })}
                            onChange={handleButtonChange}
                            error={errors.hasOwnProperty('button')}
                            helperText={
                                errors.button ? errors.button.message : 'Example: Download File'
                            }
                        />
                    </Grid>
                    <Button type="submit" color="primary" disabled={Object.keys(errors).length > 0}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
};

export default EmailContentForm;
