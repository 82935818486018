export const fileLinkBuilder = (response: any, defaultFileName: string) => {
    try {
        const type = response.headers['content-type'];
        const fileInfo = response.headers['content-disposition'];
        const fileName = fileInfo.split('attachment;filename=')[1] || defaultFileName;
        const blob = new Blob([response.data], { type: type});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    } catch (e) {
        console.log('File builder error', e);
    }
};
