import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {configure} from 'axios-hooks'
import axios from './core/setAxiosOptions';
import {SnackbarProvider} from 'notistack';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import {config, currentSiteKey} from './config/drawer';

configure({axios});

ReactDOM.render(
    <SnackbarProvider
        maxSnack={3}
        dense
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <GoogleReCaptchaProvider reCaptchaKey={currentSiteKey()} language="en">
          <App/>
        </GoogleReCaptchaProvider>
      </Suspense>
    </SnackbarProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
